<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="8">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              <span> Информация об интеграции </span>
            </b-card-title>
            <small
              v-if="integration != null && integration.oAuthId != null"
              class="text-muted"
            >
              Обновлено {{ integration.updateDateTime }}
            </small>
          </b-card-header>
          <b-card-body>
            <b-overlay
              :show="show"
              rounded="sm"
            >
              <b-col
                v-if="integration != null && integration.oAuthId != null"
                cols="12"
              >
                <b-table
                  id="info-table"
                  class="mb-1"
                  :borderless="true"
                  thead-class="d-none"
                  :items="items"
                  :fields="fields"
                  hover
                  :head-variant="null"
                >
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>

                  <template #cell(value)="data">
                    <span v-if="data.item.name !== 'Статус подключения' && data.item.name !== 'Выбранные цели'">
                      <b>{{ data.value }}</b>
                    </span>
                    <span v-if="data.item.name === 'Выбранные цели'">
                      <b-table
                          v-if="data.value != null && data.value !== ''"
                        id="goals-table"
                        class="mb-1"
                        thead-class="d-none"
                        :items="data.value"
                        :fields="goalFields"
                        :head-variant="null"
                      />
                      <b v-if="data.value === ''">цели не выбраны</b>
                    </span>
                    <span v-if="data.item.name === 'Статус подключения'">
                      <b-badge
                        :variant="statusVariant(data.value)"
                      >
                        {{ data.value }}
                      </b-badge>
                    </span>
                  </template>
                </b-table>
              </b-col>
              <div
                v-else
                class="misc-inner p-2 p-sm-3"
              >
                <div class="w-100 text-center">
                  <h2 class="mb-1">
                    Нет данных по интеграции
                  </h2>
                  <p class="mb-2">
                    Подключите аккаунт к вашей интеграции {{ integration.type }}
                  </p>
                </div>
              </div>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="mt-2"
                size="sm"
                :to="{ path:'/projects/' + integration.projectId }"
              >
                <feather-icon icon="ChevronLeftIcon" /> Вернуться к проекту
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="ml-1 mt-2"
                size="sm"
                :to="{ path:`/${mapRoutes(integration.integrationType)}/` + $route.params.connectionId }"
              >
                {{ integration.oAuthId != null ? 'Изменить подключение' : 'Подключить' }}
              </b-button>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTab,
  BLink,
  BTabs,
  BButton,
  BCardHeader,
  BCollapse, BBadge,
  BListGroup, BListGroupItem,
  BCardBody,
  BTable,
  BCardTitle, VBToggle, VBTooltip,
  BCardSubTitle, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BTable,
    BBadge,
    BCollapse,
    BCardSubTitle,
    BOverlay,
    BLink,
    BListGroup,
    BListGroupItem,
    BTab,
    BTabs,
    BRow,
    BCol,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    connectionId: {
      type: [String, Number],
      default: null,
    },
    projectId: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      message: null,
      integration: null,
      fields: [
        { key: 'name', label: '' },
        { key: 'value', label: '' },
      ],
      items: [
        { name: 'x', value: 'y', key: 'x' },
      ],
      goalFields: [
        { id: 'id цели', name: 'название' },
      ],
      show: false,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Подключено: 'success',
        'Не подключено': 'warning',
      }

      return status => statusColor[status]
    },
    statusLabel() {
      const statusLabel = {
        0: 'Подключено',
        1: 'Не подключено',
      }

      return status => statusLabel[status]
    },
    mapRoutes() {
      const mapRoutes = {
        0: 'yandex-direct',
        1: 'yandex-metrika',
        2: 'google-analytics',
        3: 'google-adwords',
      }

      return integrationType => mapRoutes[integrationType]
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    const param = this.$route.params.connectionId
    this.show = true
    this.$http.get(`/integration/info?id=${param}`).then(res => {
      const { data } = res
      this.integration = data
      switch (this.integration.integrationType) {
        // yandex direct
        case 0:
          this.items = [
            { name: 'Название', value: this.integration.name },
            { name: 'Статус подключения', value: this.statusLabel(this.integration.integrationStatus) },
          ]
          break
          // yandex metrika
        case 1:
          // eslint-disable-next-line no-case-declarations
          const info = JSON.parse(this.integration.moreInfo)
          if (info != null) {
            this.items = [
              {
                name: 'Название',
                value: this.integration.name
              },
              {
                name: 'Статус подключения',
                value: this.statusLabel(this.integration.integrationStatus)
              },
              {
                name: 'Номер счетчика',
                value: info.ClientCounter
              },
              {
                name: 'Проект Ecommerce?',
                value: info.IsTransactions ? 'Да' : 'Нет'
              },
              {
                name: 'Выбранные цели',
                value: JSON.parse(info.Goals)
              },
              {
                name: 'Срок годности токена до',
                value: new Date(info.ExpirationDate).toLocaleDateString()
              },
            ]
          } else {
            this.items = [
              {
                name: 'Название',
                value: this.integration.name
              },
              {
                name: 'Статус подключения',
                value: this.statusLabel(this.integration.integrationStatus)
              },
            ]
          }
          break
        case 2:
          // eslint-disable-next-line no-case-declarations
          const googleInfo = JSON.parse(this.integration.moreInfo)
          console.log(googleInfo, 'googleInfo')
          if (googleInfo != null) {
            this.items = [
              {
                name: 'Название',
                value: this.integration.name
              },
              {
                name: 'Статус подключения',
                value: this.statusLabel(this.integration.integrationStatus)
              },
              {
                name: 'Аккаунт',
                value: googleInfo.AccountId
              },
              {
                name: 'Ресурс',
                value: googleInfo.PropertyId
              },
              {
                name: 'Представление',
                value: googleInfo.ViewId
              },
              {
                name: 'Проект Ecommerce?',
                value: googleInfo.Transactions ? 'Да' : 'Нет'
              },
              {
                name: 'Выбранные цели',
                value: googleInfo.Goals != null ?  JSON.parse(googleInfo.Goals) : '-'
              },
              {
                name: 'Срок годности токена до',
                value: new Date(googleInfo.ExpirationDate).toLocaleDateString()
              },
            ]
          } else {
            this.items = [
              {
                name: 'Название',
                value: this.integration.name
              },
              {
                name: 'Статус подключения',
                value: this.statusLabel(this.integration.integrationStatus)
              },
            ]
          }
          break
        case 3:
          this.items = [
            { name: 'Название', value: this.integration.name },
            { name: 'Статус подключения', value: this.statusLabel(this.integration.integrationStatus) },
          ]
          break
        default:
          console.log('none category')
      }
      this.show = false
    })
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.table::v-deep  td, .table::v-deep th {
    border: none!important;
  }
</style>
